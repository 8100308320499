@tailwind base;
@tailwind components;
@tailwind utilities;




.ant-table-thead .ant-table-cell {
  background-color: #FFF;
  border-radius: 0px !important;
  border-top: 1px solid #E9E9E9 !important;
  border-bottom: 2px solid #E9E9E9 !important;
  font-size: 15px !important;
}




.menu-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.menu-item {
  width: 150px;
  height: 150px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  overflow: hidden;
}

.menu-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menu-label {
  margin-top: 8px;
  text-align: center;
  font-weight: bold;
}

.custom-tabs .ant-tabs-nav .ant-tabs-tab {
  margin-right: 5px;
  /* Adjust the space between tabs as needed */
}

.custom-tabs .ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
  margin-left: 5px;
  /* Adjust the space between tabs as needed */
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  /* Add this line to make the horizontal scrollbar thinner */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}